/* eslint-disable baseui/no-deep-imports */
/* eslint-disable no-restricted-globals */
import React from "react";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Select } from "baseui/select";
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import { cancelOrder, updateOrderStatus } from "./actions";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE,
} from "baseui/modal";
import { KIND as ButtonKind } from "baseui/button";
import { ORDER_STATUS, PERSONNEL_ROLE } from "./constants";

export default function OrderStatus() {
  let { operators } = useSelector((state) => state.operators);
  let { vehicles } = useSelector((state) => state.vehicles);
  const [value, setValue] = React.useState("driver");
  const [vehicleDriver, setVehicleDriver] = React.useState("driver");
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { order } = useSelector((state) => state.order);
  const [driverSelect, setDriverSelect] = useState([]);
  const [vehicleSelect, setVehicleSelect] = useState([]);
  const [driverOption, setDriverOption] = useState([]);
  const [vehicleOption, setVehicleOption] = useState([]);

  function assignDriver() {
    const option = confirm(
      `Are you sure you want to assign ${driverSelect[0].label} to this order`
    );

    if (option) {
      dispatch(
        updateOrderStatus(order.id, "assign-driver", {
          order_id: order.id,
          driver_id: driverSelect[0].id,
        })
      );
      setDriverSelect([]);
    }
  }

  function assignVehicle() {
    const option = confirm(
      `Are you sure you want to assign ${vehicleSelect[0].label} to this order`
    );

    if (option) {
      dispatch(
        updateOrderStatus(order.id, "confirm-carrier", {
          order_id: order.id,
          vehicle_id: vehicleSelect[0].id,
        })
      );
      setVehicleSelect([]);
    }
  }

  function dispatchOrder() {
    const option = confirm(`Are you sure you want to dispatch this order`);

    if (option) {
      dispatch(updateOrderStatus(order.id, "dispatch", {}));
    }
  }

  function cancelledOrder() {
    dispatch(cancelOrder(order.id, "cancel", {}));
    setIsOpen(false);
  }

  function inTransitOrder() {
    const option = confirm(`Are you sure this order is currently in transit?`);

    if (option) {
      dispatch(updateOrderStatus(order.id, "in-transit", {}));
    }
  }

  function arrivedToOrigin() {
    const option = confirm(`Are you sure this order is headed to the origin?`);

    if (option) {
      dispatch(updateOrderStatus(order.id, "arrive-origin"));
    }
  }

  function orderAtDestination() {
    const option = confirm(`Are you sure the order is at destination?`);

    if (option) {
      dispatch(updateOrderStatus(order.id, "arrive-destination"));
    }
  }

  function completeOrder() {
    const option = confirm(`Are you sure the order is complete?`);

    if (option) {
      dispatch(updateOrderStatus(order.id, "complete"));
    }
  }

  function changeDriver() {
    const option = confirm(
      `Are you sure you want to assign ${driverOption[0].label} to this order`
    );

    if (option) {
      dispatch(
        updateOrderStatus(order.id, "change-driver", {
          driver_id: driverOption[0].id,
        })
      );
      setDriverOption([]);
    }
  }

  function chnageVehicle() {
    const option = confirm(
      `Are you sure you want to assign ${vehicleOption[0].label} to this order`
    );

    if (option) {
      dispatch(
        updateOrderStatus(order.id, "change-vehicle", {
          vehicle_id: vehicleOption[0].id,
        })
      );
      setVehicleOption([]);
    }
  }

  function resetOrder() {
    const option = confirm(`Are you sure you want to reset this order?`);

    if (option) {
      dispatch(updateOrderStatus(order.id, "reset"));
    }
  }

  return (
    <Block marginBottom={"16"}>
      {/*Modal start*/}
      <Modal
        onClose={() => setIsOpen(false)}
        closeable
        isOpen={isOpen}
        animate
        autoFocus
        size={SIZE.default}
        role={ROLE.dialog}
      >
        <ModalHeader>Attention Required</ModalHeader>
        <ModalBody>
          You are about to cancel this order. Are you sure you want to proceed?
        </ModalBody>
        <ModalFooter>
          <ModalButton kind={ButtonKind.secondary}>No</ModalButton>
          <ModalButton kind={ButtonKind.primary} onClick={cancelledOrder}>
            Yes
          </ModalButton>
        </ModalFooter>
      </Modal>
      <br />
      {(order.order_status_id === ORDER_STATUS.created ||
        order.order_status_id === ORDER_STATUS.matchingCarriers ||
        order.order_status_id === ORDER_STATUS.driverAssigned ||
        order.order_status_id === ORDER_STATUS.carrierConfirmed ||
        order.order_status_id === ORDER_STATUS.inTransitToPickup ||
        order.order_status_id === ORDER_STATUS.atOrigin ||
        (user?.role === PERSONNEL_ROLE.admin &&
          (order.order_status_id === ORDER_STATUS.inTransit ||
            order.order_status_id === ORDER_STATUS.atDestination))) && (
        <>
          <RadioGroup
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
            name="number"
            align={ALIGN.horizontal}
          >
            <Radio value="driver">Assign driver</Radio>
            <Radio value="vehicle">Assign vehicle</Radio>
          </RadioGroup>
          {value === "driver" && (
            <Block display={"flex"} flexDirection={"row"} marginBottom={"16px"}>
              <Block flex={"1 1 0"}>
                <Select
                  id={'assign-driver-select'}
                  options={
                    operators &&
                    operators.map((item) => {
                      return {
                        id: item.id,
                        label: item.name,
                      };
                    })
                  }
                  labelKey="label"
                  valueKey={"id"}
                  multi={false}
                  onChange={(e) => {
                    setDriverSelect((prev) => [...e.value]);
                  }}
                  value={driverSelect}
                />
              </Block>
              <Block marginLeft={"8px"}>
                <Button
                  id={'assign-driver-save-button'}
                  onClick={assignDriver}
                  disabled={driverSelect.length > 0 ? false : true}
                >
                  Save
                </Button>
              </Block>
            </Block>
          )}

          {value === "vehicle" && (
            <Block display={"flex"} flexDirection={"row"} marginBottom={"16px"}>
              <Block flex={"1 1 0"}>
                <Select
                  options={
                    vehicles &&
                    vehicles.map((item) => {
                      return {
                        id: item.id,
                        label: item.licence_plate,
                      };
                    })
                  }
                  labelKey="label"
                  valueKey={"id"}
                  multi={false}
                  onChange={(e) => {
                    setVehicleSelect((prev) => [...e.value]);
                  }}
                  value={vehicleSelect}
                />
              </Block>
              <Block marginLeft={"8px"}>
                <Button
                  onClick={assignVehicle}
                  disabled={vehicleSelect.length > 0 ? false : true}
                >
                  Save
                </Button>
              </Block>
            </Block>
          )}
        </>
      )}

      {user?.role === PERSONNEL_ROLE.admin &&
        order.order_status_id === ORDER_STATUS.delivered && (
          <>
            <RadioGroup
              value={vehicleDriver}
              onChange={(e) => setVehicleDriver(e.currentTarget.value)}
              name="number"
              align={ALIGN.horizontal}
            >
              <Radio value="driver">Change driver</Radio>
              <Radio value="vehicle">Change vehicle</Radio>
            </RadioGroup>
            {vehicleDriver === "driver" && (
              <Block
                display={"flex"}
                flexDirection={"row"}
                marginBottom={"16px"}
              >
                <Block flex={"1 1 0"}>
                  <Select
                    options={
                      operators &&
                      operators.map((item) => {
                        return {
                          id: item.id,
                          label: item.name,
                        };
                      })
                    }
                    labelKey="label"
                    valueKey={"id"}
                    multi={false}
                    onChange={(e) => {
                      setDriverOption((prev) => [...e.value]);
                    }}
                    value={driverOption}
                  />
                </Block>
                <Block marginLeft={"8px"}>
                  <Button
                    onClick={changeDriver}
                    disabled={driverOption.length > 0 ? false : true}
                  >
                    Save
                  </Button>
                </Block>
              </Block>
            )}

            {vehicleDriver === "vehicle" && (
              <Block
                display={"flex"}
                flexDirection={"row"}
                marginBottom={"16px"}
              >
                <Block flex={"1 1 0"}>
                  <Select
                    options={
                      vehicles &&
                      vehicles.map((item) => {
                        return {
                          id: item.id,
                          label: item.licence_plate,
                        };
                      })
                    }
                    labelKey="label"
                    valueKey={"id"}
                    multi={false}
                    onChange={(e) => {
                      setVehicleOption((prev) => [...e.value]);
                    }}
                    value={vehicleOption}
                  />
                </Block>
                <Block marginLeft={"8px"}>
                  <Button
                    onClick={chnageVehicle}
                    disabled={vehicleOption.length > 0 ? false : true}
                  >
                    Save
                  </Button>
                </Block>
              </Block>
            )}
          </>
        )}

      {(order.order_status_id === "driver_assigned" ||
        order.order_status_id === "carrier_confirmed") && (
        <Button onClick={dispatchOrder}>Dispatch</Button>
      )}

      {order.order_status_id === "in_transit_to_pickup" && (
        <Button onClick={arrivedToOrigin}>At Pickup</Button>
      )}

      {order.order_status_id === "at_origin" && (
        <Button onClick={inTransitOrder}>Set Order To Transit</Button>
      )}
      {order.order_status_id === "in_transit" && (
        <Button onClick={orderAtDestination}>Order At Dropoff</Button>
      )}
      {order.order_status_id === "at_destination" && (
        <Button onClick={completeOrder}>Complete</Button>
      )}
      {user?.role === PERSONNEL_ROLE.admin &&
        (order.order_status_id === "delivered" ||
          order.order_status_id === "cancelled") && (
          <Button kind="secondary" onClick={resetOrder}>
            Reset Order
          </Button>
        )}
      {user?.role === PERSONNEL_ROLE.admin &&
        order.order_status_id !== "cancelled" && (
          <Button
            kind="secondary"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            style={{
              marginLeft: "12px",
            }}
          >
            Cancel
          </Button>
        )}
    </Block>
  );
}
